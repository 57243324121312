var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_vm._m(0),_c('div',{staticClass:"introduce"},[_vm._m(1),_c('div',{staticClass:"characteristic"},[_c('div',{staticClass:"child"},[_c('div',{staticClass:"title m-b-16"},[_vm._v("产品特点")]),_c('div',{staticClass:"characteristic-item"},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":require('../assets/images/three-dimensional.png'),"alt":""}})]),_vm._m(2)]),_c('div',{staticClass:"characteristic-item m-t-40"},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":require('../assets/images/voice.png'),"alt":""}})]),_vm._m(3)]),_c('div',{staticClass:"characteristic-item m-t-17"},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":require('../assets/images/comprehensive.png'),"alt":""}})]),_vm._m(4)]),_c('div',{staticClass:"characteristic-item m-t-17"},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":require('../assets/images/multiterminal.png'),"alt":""}})]),_vm._m(5)])])]),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-banner"},[_c('img',{attrs:{"src":require("../assets/images/banner.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-introduce"},[_c('div',{staticClass:"title"},[_vm._v("产品介绍")]),_c('div',{staticClass:"describeText"},[_vm._v("M-Land元宇宙生态圈是集政府、企业、服务机构等多个层面的云端生态系统。每个用户在M-Land元宇宙生态圈上将有属于自己独一无二的星球展示元空间，是由线下真实场景映射到线上的虚拟空间。")]),_c('div',{staticClass:"describeText"},[_vm._v("M-Land生态圈可应用于政府招商园区虚拟空间展示、企业虚拟空间展示、企业发布会、政企双方沉浸式远程会议、政府党建、文旅等众多领域，可让参与者迅速通过多渠道终端设备进入虚拟空间，“面对面”交流、协同、互动。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"describe"},[_c('div',{staticClass:"describe-title"},[_vm._v("三维全景展示")]),_c('div',{staticClass:"describe-txt"},[_c('div',{staticClass:"txt-item"},[_vm._v("可定制专属3D展厅，虚拟世界创意更加天马行空。")]),_c('div',{staticClass:"txt-item"},[_vm._v(" 高精度复刻线下营商环境，虚实结合，给用户带来沉浸感。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"describe"},[_c('div',{staticClass:"describe-title"},[_vm._v("语音即时沟通")]),_c('div',{staticClass:"describe-txt"},[_c('div',{staticClass:"txt-item"},[_vm._v("语音通话更直接，音画同步线上推介；")]),_c('div',{staticClass:"txt-item"},[_vm._v(" 打破时间和空间的限制，随时建立社交连接。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"describe"},[_c('div',{staticClass:"describe-title"},[_vm._v("全面数据展示")]),_c('div',{staticClass:"describe-txt"},[_c('div',{staticClass:"txt-item"},[_vm._v("区域产业链深度分析，绘制产业地图；")]),_c('div',{staticClass:"txt-item"},[_vm._v(" 重点企业信息一目了然，有的放矢精准服务。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"describe"},[_c('div',{staticClass:"describe-title"},[_vm._v("多端支持参会")]),_c('div',{staticClass:"describe-txt"},[_c('div',{staticClass:"txt-item"},[_vm._v("支持移动端，电脑端，虚拟现实设备同时登录；")]),_c('div',{staticClass:"txt-item"},[_vm._v(" 助力主办方提升活动组织与运营效率。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-price"},[_c('div',{staticClass:"child"},[_c('div',{staticClass:"title"},[_vm._v("用户价值")]),_c('div',{staticClass:"price-item"},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":require("../assets/images/price1.png"),"alt":""}})]),_c('p',[_vm._v("沉浸式参观考察招商载体和资源配套")])]),_c('div',{staticClass:"price-item"},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":require("../assets/images/price2.png"),"alt":""}})]),_c('p',[_vm._v("可视化展示检索产业布局和经济指标")])]),_c('div',{staticClass:"price-item"},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":require("../assets/images/price3.png"),"alt":""}})]),_c('p',[_vm._v("跨时空语音交流招商政策和项目信息")])]),_c('div',{staticClass:"price-item"},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":require("../assets/images/price4.png"),"alt":""}})]),_c('p',[_vm._v("可持续线上链接政企双方和招商推介")])])])])
}]

export { render, staticRenderFns }