<template>
  <div class="home">
    <div class="top-banner">
      <img src="../assets/images/banner.jpg" alt="">
    </div>
    <div class="introduce">
      <div class="product-introduce">
        <div class="title">产品介绍</div>
        <div class="describeText">M-Land元宇宙生态圈是集政府、企业、服务机构等多个层面的云端生态系统。每个用户在M-Land元宇宙生态圈上将有属于自己独一无二的星球展示元空间，是由线下真实场景映射到线上的虚拟空间。</div>
        <div class="describeText">M-Land生态圈可应用于政府招商园区虚拟空间展示、企业虚拟空间展示、企业发布会、政企双方沉浸式远程会议、政府党建、文旅等众多领域，可让参与者迅速通过多渠道终端设备进入虚拟空间，“面对面”交流、协同、互动。</div>
      </div>

      <div class="characteristic">
        <div class="child">
          <div class="title m-b-16">产品特点</div>
          <div class="characteristic-item">
            <div class="image">
              <img :src="require('../assets/images/three-dimensional.png')" alt="">
            </div>
            <div class="describe">
              <div class="describe-title">三维全景展示</div>
              <div class="describe-txt">
                <div class="txt-item">可定制专属3D展厅，虚拟世界创意更加天马行空。</div>
                <div class="txt-item"> 高精度复刻线下营商环境，虚实结合，给用户带来沉浸感。</div>
              </div>
            </div>
          </div>
          <div class="characteristic-item m-t-40">
            <div class="image">
              <img :src="require('../assets/images/voice.png')" alt="">
            </div>
            <div class="describe">
              <div class="describe-title">语音即时沟通</div>
              <div class="describe-txt">
                <div class="txt-item">语音通话更直接，音画同步线上推介；</div>
                <div class="txt-item"> 打破时间和空间的限制，随时建立社交连接。</div>
              </div>
            </div>
          </div>
          <div class="characteristic-item m-t-17">
            <div class="image">
              <img :src="require('../assets/images/comprehensive.png')" alt="">
            </div>
            <div class="describe">
              <div class="describe-title">全面数据展示</div>
              <div class="describe-txt">
                <div class="txt-item">区域产业链深度分析，绘制产业地图；</div>
                <div class="txt-item"> 重点企业信息一目了然，有的放矢精准服务。</div>
              </div>
            </div>
          </div>
          <div class="characteristic-item m-t-17">
            <div class="image">
              <img :src="require('../assets/images/multiterminal.png')" alt="">
            </div>
            <div class="describe">
              <div class="describe-title">多端支持参会</div>
              <div class="describe-txt">
                <div class="txt-item">支持移动端，电脑端，虚拟现实设备同时登录；</div>
                <div class="txt-item"> 助力主办方提升活动组织与运营效率。</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="user-price">
        <div class="child">
          <div class="title">用户价值</div>
          <div class="price-item">
            <div class="image">
              <img src="../assets/images/price1.png" alt="">
            </div>
            <p>沉浸式参观考察招商载体和资源配套</p>
          </div>
          <div class="price-item">
            <div class="image">
              <img src="../assets/images/price2.png" alt="">
            </div>
            <p>可视化展示检索产业布局和经济指标</p>
          </div>
          <div class="price-item">
            <div class="image">
              <img src="../assets/images/price3.png" alt="">
            </div>
            <p>跨时空语音交流招商政策和项目信息</p>
          </div>
          <div class="price-item">
            <div class="image">
              <img src="../assets/images/price4.png" alt="">
            </div>
            <p>可持续线上链接政企双方和招商推介</p>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import '@/assets/style/home.css'
export default {
  name: 'HomeView',
}
</script>
